<!-- src/App.vue -->

<template>
  <div id="app">
    <header>
      <!-- 可能会有一些标题或导航链接 -->
    </header>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
/* 这里可以放置全局样式 */
</style>

