import { createRouter, createWebHistory } from 'vue-router';

import User from './components/User.vue';
import Quiz from './components/Quiz.vue';
import Admin from './components/Admin.vue';

const routes = [
  {
    path: '/',
    name:'User',
    component: User
  },
  {
    path: '/quiz/:name/:studentNumber',
    name: 'Quiz',
    component: Quiz,
    props:true
  },
  {
    path: '/admin',
    component: Admin
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

