<template>
  <div class="admin-container">
    <div class="login-box" v-if="!loggedIn">
      <h2>Admin Login</h2>
      <div class="input-field">
        <label for="username">Username:</label>
        <input id="username" v-model="username" placeholder="Username" />
      </div>
      <div class="input-field">
        <label for="password">Password:</label>
        <input id="password" v-model="password" placeholder="Password" type="password" />
      </div>
      <button class="btn" @click="handleLogin">Login</button>
    </div>

    <div v-if="loggedIn">
      <div class="filter-section">
        <select v-model="userType">
          <option value="all">所有用户</option>
          <option value="student">学生</option>
          <option value="teacher">教职工</option>
        </select>

        <select v-model="organization">
          <option value="all">全部组织</option>
<option>文学院党委</option>
<option>新闻与传播学院党委</option>
<option>历史文化学院党委</option>
<option>哲学社会学院党委</option>
<option>经济学院党委</option>
<option>管理学院党委</option>
<option>外国语学院党委</option>
<option>法学院党委</option>
<option>马克思主义学院党委</option>
<option>艺术学院党委</option>
<option>威尔士学院党委</option>
<option>政治与国际关系学院党委</option>
<option>高等教育研究院党委</option>
<option>青藏高原人文环境研究院党委</option>
<option>数学与统计学院党委</option>
<option>物理科学与技术学院党委</option>
<option>核科学与技术学院党委</option>
<option>化学化工学院党委</option>
<option>信息科学与工程学院党委</option>
<option>草地农业科技学院党委</option>
<option>生命科学学院党委</option>
<option>大气科学学院党委</option>
<option>资源环境学院党委</option>
<option>土木工程与力学学院党委</option>
<option>地质科学与矿产资源学院党委</option>
<option>材料与能源学院党委</option>
<option>生态学院党委</option>
<option>药学院党委</option>
<option>基础医学院党委</option>
<option>口腔医学院（口腔医院）党委</option>
<option>公共卫生学院党委</option>
<option>护理学院党委</option>
<option>动物医学与生物安全学院党委</option>
<option>萃英学院党委</option>
<option>体育教研部党委</option>
<option>网络与继续教育学院党委</option>
<option>出版社党委</option>
<option>国际文化交流学院直属党支部</option>
<option>图书馆及直属单位党委</option>
<option>后勤保障部党委</option>
<option>资产经营有限公司党委</option>
<option>机关党委</option>
<option>离退休教职工党委</option>
<option>第一医院（第一临床医学院）党委</option>
<option>第二医院（第二临床医学院）党委</option>        
</select>

        <button @click="fetchStudentsData">搜索</button>
      </div>

      <table>
        <thead>
            <tr>
                <th>姓名</th>
                <th>学号/身份证号</th>
                <th>类别</th>
                <th>组织</th>
                <th>联系方式</th>
                <th>最高得分</th>
                <th>用时</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(student, index) in students" :key="index">
                <td>{{ student.name }}</td>
                <td>{{ student.studentNumber }}</td>
                <td>{{ student.personType}}</td>
                <td>{{ student.organization }}</td>
                <td>{{ student.phoneNumber }}</td>
                <td>{{ student.score }}</td>
                <td>{{ student.quizDuration }}秒</td>
            </tr>
        </tbody>
      </table>

<button class="btn download-btn" @click="downloadExcel" v-if="students.length">Download as Excel</button>

    </div>
  </div>
</template>




<script>
// 阻止 F12 快捷键打开控制台
document.addEventListener('keydown', function(e) {
    if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
    }
});

// 阻止右键菜单中打开控制台选项
document.addEventListener('contextmenu', function(e) {
    e.preventDefault();
});

import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      loggedIn: false,
      students: [],
      userType: 'all',
      organization: 'all',
      scoreOrder: 'desc'
    };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await axios.post('/api/admin/login', {
          username: this.username,
          password: this.password
        });
        if (response.data.message === "Login successful!") {
            // 将token保存到localStorage中
            localStorage.setItem('token', response.data.token);
            this.loggedIn = true;
            this.fetchStudentsData();
        }
      } catch (error) {
        console.error("Error logging in:", error);
      }
    },

    async fetchStudentsData() {
      try {
        const token = localStorage.getItem('token');
        const studentData = await axios.get('/api/admin/data', {
          params: {
            userType: this.userType,
            organization: this.organization,
            scoreOrder: this.scoreOrder
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.students = studentData.data;

      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    },


async downloadExcel() {
    try {
        const token = localStorage.getItem('token'); // 从localStorage中获取token

        const response = await axios.get('/api/admin/down', {
            responseType: 'blob',
            params: {
                userType: this.userType,
                organization: this.organization,
                scoreOrder: this.scoreOrder
            },
            headers: {
                'Authorization': `Bearer ${token}`  // 将token添加到请求头中
            }
        });
        
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'students_data.xlsx';
        link.click();
    } catch (error) {
        console.error("Error downloading Excel:", error);
    }
}



  }
}
</script>

<style scoped>
.admin-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-box {
  text-align: center;
  padding: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  background-color: #007BFF;
  color: white;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

.download-btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
