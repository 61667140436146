import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

// 设置axios的默认baseURL，确保它指向您的后端服务器地址和端口
//axios.defaults.baseURL = 'https://dtxt.lzu.edu.cn/'; 
//axios.defaults.baseURL = 'http://10.8.13.165';
axios.defaults.baseURL = '';
// 创建Vue应用实例并挂载
const app = createApp(App);

// 使用路由和状态管理
app.use(router);

// 挂载应用到#app元素
app.mount('#app');

