<template>
  <div class="container">
    <div>
      <h2>用户信息</h2>
      <div class="input-group">
        <label for="name">姓名:</label>
        <input type="text" id="name" v-model="name" placeholder="请输入姓名">
      </div>

      <div class="input-group">
        <label for="personType">类型:</label>
        <select id="personType" v-model="personType">
          <option value="" disabled>请选择身份</option>
          <option value="0">教工</option>
          <option value="1">学生</option>
        </select>
      </div>

      <div class="input-group">
        <label for="studentNumber">校园卡号或身份证号:</label>
        <input type="text" id="studentNumber" v-model="studentNumber" placeholder="请输入校园卡号或身份证号">
      </div>

      <div class="input-group">
        <label for="phoneNumber">联系方式:</label>
        <input type="text" id="phoneNumber" v-model="phoneNumber" placeholder="请输入联系方式"/>
      </div>

      <div class="input-group">
        <label for="organization">二级党组织:</label>
        <select id="organization" v-model="organization">
          <option disabled value="">请选择二级党组织</option>
            <option>文学院党委</option>
            <option>新闻与传播学院党委</option>
            <option>历史文化学院党委</option>
            <option>哲学社会学院党委</option>
            <option>经济学院党委</option>
            <option>管理学院党委</option>
            <option>外国语学院党委</option>
            <option>法学院党委</option>
            <option>马克思主义学院党委</option>
            <option>艺术学院党委</option>
            <option>威尔士学院党委</option>
            <option>政治与国际关系学院党委</option>
            <option>高等教育研究院党委</option>
            <option>青藏高原人文环境研究院党委</option>
            <option>数学与统计学院党委</option>
            <option>物理科学与技术学院党委</option>
            <option>核科学与技术学院党委</option>
            <option>化学化工学院党委</option>
            <option>信息科学与工程学院党委</option>
            <option>草地农业科技学院党委</option>
            <option>生命科学学院党委</option>
            <option>大气科学学院党委</option>
            <option>资源环境学院党委</option>
            <option>土木工程与力学学院党委</option>
            <option>地质科学与矿产资源学院党委</option>
            <option>材料与能源学院党委</option>
            <option>生态学院党委</option>
            <option>药学院党委</option>
            <option>基础医学院党委</option>
            <option>口腔医学院（口腔医院）党委</option>
            <option>公共卫生学院党委</option>
            <option>护理学院党委</option>
            <option>动物医学与生物安全学院党委</option>
            <option>萃英学院党委</option>
            <option>体育教研部党委</option>
            <option>网络与继续教育学院党委</option>
            <option>出版社党委</option>
            <option>国际文化交流学院直属党支部</option>
            <option>图书馆及直属单位党委</option>
            <option>后勤保障部党委</option>
            <option>资产经营有限公司党委</option>
            <option>机关党委</option>
            <option>离退休教职工党委</option>
            <option>第一医院（第一临床医学院）党委</option>
            <option>第二医院（第二临床医学院）党委</option>
        </select>
      </div>

      <button @click="submit" class="submit-btn">提交</button>
    </div>
  </div>
</template>

<style scoped>
/* Existing styles */

.container {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

input, select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* 为按钮之间增加20px的间隔 */
}

.btn-choice {
  padding: 10px 30px; /* 增加内部填充，使按钮看起来更大 */
  border: none; /* 移除默认的边框 */
  background-color: #007BFF; /* 使用一个蓝色背景 */
  color: #fff; /* 文字颜色为白色 */
  font-weight: 600; /* 增加字体的粗细 */
  border-radius: 20px; /* 圆角效果 */
  cursor: pointer; /* 当鼠标移到按钮上时，鼠标指针为手型 */
  transition: background-color 0.3s; /* 过渡效果，使按钮在鼠标悬停时平滑地改变背景色 */
}

.btn-choice:hover {
  background-color: #0056b3; /* 鼠标悬停时的背景色 */
}


.submit-btn {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #0056b3;
}

</style>

<script>
import { ref, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';


export default {
  name: 'UserForm',
  setup() {
    const name = ref('');
    const studentNumber = ref('');
    const organization = ref('');
    const phoneNumber = ref('');
    const router = useRouter();
    const personType = ref('');
    const showIntroduction = ref(true);


    // Add the following code to block console
    const blockConsole = (e) => {
      if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
      }
    };

    // Add the following code to block context menu
    const blockContextMenu = (e) => {
      e.preventDefault();
    };

    // Attach event listeners to block console and context menu
    document.addEventListener('keydown', blockConsole);
    document.addEventListener('contextmenu', blockContextMenu);

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', blockConsole);
      document.removeEventListener('contextmenu', blockContextMenu);
    });


    const submit = async () => {
      if(!name.value.trim() || !studentNumber.value.trim() || !organization.value || !phoneNumber.value || !personType.value) {
        alert('请填写所有信息。');
        return;
      }
      try {
        const response = await axios.post('/api/user/submit', {
          name: name.value,
          studentNumber: studentNumber.value,
          organization: organization.value,
          phoneNumber: phoneNumber.value,
          personType: personType.value
        });

        if(response.data.message === "You can proceed with the quiz." || response.data.message === "User info submitted successfully! You can proceed with the quiz.") {
          router.push({ name: 'Quiz', params: { personType: personType.value, name: name.value, studentNumber: studentNumber.value, organization: organization.value } });
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          alert(error.response.data.message);
        } else if (error.response && error.response.status === 400) {
          alert(error.response.data.message);
        } else {
          console.error("Error while submitting user info:", error);
        }
      }
    }

    return {
      name,
      studentNumber,
      organization,
      phoneNumber,
      submit,
      personType,
      showIntroduction
    };
  }
}
</script>

